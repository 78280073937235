<template src="./PendingToFinish.html"></template>
<script>
import { getPendingToFinish } from '../../../services'
import { getTodayFormat, formatDate } from '../../../utils/computeds'

export default {
  name: 'PendingToFinish',
  data () {
    return {
      pendingToFinish: [],
      format: 'DD [de] MMMM, YYYY'
    }
  },
  created () {
    this.loadPendingToFinish()
  },
  methods: {
    async loadPendingToFinish () {
      try {
        this.pendingToFinish = await getPendingToFinish()
      } catch (error) {
        console.log('Error on get pendingToFinish =>', error)
      }
    }
  },
  computed: {
    getTodayFormat,
    formatDate
  }
}
</script>
