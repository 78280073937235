<template src="./ServicesByAnalyst.html"></template>
<script>
import { getServicesByAnalyst } from '../../../services'
import { getTodayFormat, formatDate } from '../../../utils/computeds'

export default {
  name: 'ServicesByAnalyst',
  data () {
    return {
      tab: 0,
      analyst: {},
      analysts: [],
      servicesByAnalyst: [],
      format: 'DD [de] MMMM, YYYY'
    }
  },
  created () {
    this.loadServicesByAnalyst()
  },
  methods: {
    async loadServicesByAnalyst () {
      try {
        this.servicesByAnalyst = await getServicesByAnalyst()
        this.analysts = this.servicesByAnalyst.map(analyst => analyst.fullName)
        this.analyst = this.servicesByAnalyst[0]
      } catch (error) {
        console.log('Error on get pendingToFinish =>', error)
      }
    },
    filterAnalyst (analyst) {
      this.analyst = this.servicesByAnalyst.find(service => service.fullName === analyst)
    }
  },
  computed: {
    getTodayFormat,
    formatDate
  }
}
</script>
