<template src="./EditInvoice.html"></template>

<script>
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import { getInvoice, updateInvoice, removeInvoiceInProcesses } from '../../../services'
import {
  formatDateUTC,
  getStatusServiceChip,
  getServiceStatus
} from '../../../utils/computeds'
import { SERVICE_STATUS } from '../../../utils/constants'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

export default {
  name: 'EditInvoice',
  data () {
    return {
      SERVICE_STATUS,
      invoice: {},
      menuUpdate: false,
      processes: [],
      services: [],
      calcInvoice: {
        services: 0,
        extras: 0,
        total: 0
      },
      panels: [],
      checkedAll: false,
      processesChecked: []
    }
  },
  validations () {
    return {
      invoice: {
        date: { required },
        number: { required },
        total: { required }
      }
    }
  },
  created () {
    this.loadInvoice(this.$route.params.id)
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    async loadInvoice (invoiceId) {
      try {
        const { invoice, processes } = await getInvoice(invoiceId)
        this.processes = processes
        this.invoice = invoice
        this.invoice.date = moment(this.invoice.date).format('YYYY-MM-DD')
      } catch (error) {
        console.log('Error on get invoice =>', error)
      }
    },
    async update () {
      try {
        await updateInvoice(this.invoice.id, this.invoice)
        await this.loadInvoice(this.invoice.id)
        this.$swal({
          text: 'Se actualizo la factura.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
      } catch (error) {
        console.log('Error on update invoice =>', error)
        this.$swal({
          text: error.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    seeAllProcess (quantity) {
      if (quantity === 0) this.panels = []
      else this.panels = [...Array(quantity + 1).keys()]
    },
    calculate () {
      this.services = []
      const services = []
      this.calcInvoice = this.processesChecked.reduce(
        (res, processId) => {
          const process = this.processes.find((process) => process.id === processId)
          const [service] = process.serviceByProcess

          if (!service.groupId) {
            services.push({
              name: service.name,
              quantity: 1,
              total: service.value
            })
          } else {
            const someCancelled = process.serviceByProcess.some(
              (service) => service.status === SERVICE_STATUS.CANCELLED
            )
            if (!someCancelled) {
              services.push({
                name: service.groupName,
                quantity: 1,
                total: service.groupTotal
              })
            } else {
              services.push({
                name: 'Otros Servicios',
                quantity: 1,
                total: process.total
              })
            }
          }
          res.extras += process.extraCost
          res.services += process.total

          return res
        },
        { services: 0, extras: 0, total: 0 }
      )
      this.calcInvoice.total = this.calcInvoice.services + this.calcInvoice.extras

      this.services = services.reduce((res, service) => {
        const index = res.findIndex((item) => item.name === service.name)
        if (index !== -1) {
          res[index].total += service.total
          res[index].quantity += service.quantity
        } else {
          res.push(service)
        }
        return res
      }, [])
    },
    check (e, process) {
      e.cancelBubble = true
      this.checkedAll = false

      if (this.processesChecked.includes(process.id)) {
        this.processesChecked = this.processesChecked.filter(
          (processId) => processId !== process.id
        )
        process.checked = false
      } else {
        this.processesChecked.push(process.id)
        process.checked = true
      }
      this.calculate()
    },
    checkAll (e) {
      e.cancelBubble = true

      if (!this.checkedAll) this.processesChecked = []
      else this.processesChecked = this.processes.map((process) => process.id)

      for (const process of this.processes) {
        process.checked = this.checkedAll
      }
      this.calculate()
    },
    ifDeleteProcesses () {
      this.$swal({
        title: `¿Estas seguro de eliminar los ${this.processesChecked.length} procesos?`,
        icon: 'warning',
        buttons: true,
        dangerMode: true
      }).then((value) => {
        if (value) {
          this.deleteProcesses()
        }
      })
    },
    async deleteProcesses () {
      try {
        await removeInvoiceInProcesses(this.invoice.id, this.processesChecked)
        await this.loadInvoice(this.invoice.id)
        this.$swal({
          text: `Se retiraron los ${this.processesChecked.length} procesos de la factura.`,
          buttons: false,
          icon: 'success',
          timer: 3000
        })
      } catch (error) {
        console.log('Error on remove invoice in procesess =>', error)
        this.$swal({
          text: 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    downloadExcel () {
      const processes = this.processes.filter(process => this.processesChecked.includes(process.id))
      const jsonData = processes.map((process) => {
        const { done, cancelled } = process.serviceByProcess.reduce(
          (acc, service) => {
            if (service.status === SERVICE_STATUS.CANCELLED) {
              acc.cancelled.push(service.name)
            } else {
              acc.done.push(service.name)
            }
            return acc
          },
          { done: [], cancelled: [] }
        )

        return {
          'Proceso #': process.id,
          'Nombre Evaluado': process.evaluated.fullName,
          'Documento Identidad': process.evaluated.documentId,
          Solicitante: `${process.userCreated.name} ${process.userCreated.lastName}`,
          'Correo Solicitante': process.userCreated.email,
          'Fecha de Solicitud': this.formatDateUTC(process.createdAt),
          'Fecha de Entrega': this.formatDateUTC(process.finalizedAt),
          Viaticos: process.extraCost,
          Ciudad: process.city,
          Observaciones: process.observation,
          'Servicio Solicitado': process.serviceByProcess.length === 1
            ? process.serviceByProcess[0].name
            : process.serviceByProcess[0].groupName,
          Total: process.total,
          'Servicios Realizados': done.join(', '),
          'Servicios Cancelados': cancelled.join(', ')
        }
      })

      const worksheet = XLSX.utils.json_to_sheet(jsonData)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Procesos')

      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      })
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' })
      saveAs(data, `Factura ${this.invoice.number}.xlsx`)
    }
  },
  computed: {
    formatDateUTC,
    getStatusServiceChip,
    getServiceStatus
  }
}
</script>
