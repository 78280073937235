import {
  Login,
  Home,
  CreateUser,
  Company,
  ListCompanies,
  Service,
  ListUsers,
  ListServices,
  CompanyServices,
  CreateProcess,
  ListProcess,
  Process,
  Invoice,
  ListInvoices,
  CreateCompliance,
  ComplianceCompany,
  ListCompliance,
  ReportHours,
  ComplianceByUser,
  ComplianceManager,
  Indicators,
  EditInvoice,
  PendingToFinish,
  ServicesByAnalyst
} from '../views'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Inicio',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/usuario',
    name: 'Usuario',
    component: CreateUser,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/buscar-usuarios',
    name: 'Buscar Usuarios',
    component: ListUsers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/empresa',
    name: 'Empresa',
    component: Company,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/servicios-empresa/:id',
    name: 'Servicios por empresa',
    component: CompanyServices,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/buscar-empresas',
    name: 'Buscar Empresas',
    component: ListCompanies,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/servicios',
    name: 'Servicios',
    component: Service,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lista-servicios',
    name: 'Lista Servicios',
    component: ListServices,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/crear-proceso',
    name: 'Crear Proceso',
    component: CreateProcess,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/buscar-proceso',
    name: 'Buscar Proceso',
    component: ListProcess,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/proceso/:id',
    name: 'Proceso',
    component: Process,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/facturacion',
    name: 'Facturación',
    component: Invoice,
    meta: {
      requiresAuth: true,
      whatsapp: false
    }
  },
  {
    path: '/lista-facturacion',
    name: 'Lista de facturación',
    component: ListInvoices,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/factura/:id',
    name: 'Factura',
    component: EditInvoice,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cumplimiento',
    name: 'Cumplimiento',
    component: CreateCompliance,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cumplimiento-empresa/:id',
    name: 'Cumplimiento por empresa',
    component: ComplianceCompany,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/planes-cumplimiento',
    name: 'Planes Cumplimiento',
    component: ListCompliance,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reportar-horas',
    name: 'Reportar Horas',
    component: ReportHours,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cumplimiento-usuario',
    name: 'Cumplimiento por usuario',
    component: ComplianceByUser,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cumplimiento-admin',
    name: 'Cumplimiento usuarios',
    component: ComplianceManager,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/indicadores',
    name: 'Indicadores',
    component: Indicators,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notificaciones/pendientes-finalizar',
    name: 'PendingToFinish',
    component: PendingToFinish,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notificaciones/servicios-analistas',
    name: 'ServicesByAnalyst',
    component: ServicesByAnalyst,
    meta: {
      requiresAuth: true
    }
  }
]

export default routes
