<template src="./ComplianceManager.html"></template>

<script>
import { OnlyAdmin, AdminCompliance } from '../../../utils/roles'
import { mapGetters } from 'vuex'
import { getUsersCompliance, getComplianceByCompany } from '../../../services'
import { formatToTwoDecimals } from '../../../utils/computeds'

export default {
  name: 'ComplianceManager',
  data () {
    return {
      usersData: [],
      companiesData: [],
      sortOrder: 'ASC',
      sortField: 'hoursExecutedWeekly'
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    OnlyAdmin,
    AdminCompliance
  },
  created () {
    this.fetchUsersData()
    this.fetchCompaniesData()
  },
  methods: {
    async fetchUsersData () {
      try {
        const response = await getUsersCompliance()
        this.usersData = response
      } catch (error) {
        console.error('Error fetching users data:', error)
      }
    },
    toggleSortOrder (field) {
      if (this.sortField === field) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortField = field
        this.sortOrder = 'asc'
      }
      this.sortCompaniesData()
    },
    async fetchCompaniesData () {
      try {
        const response = await getComplianceByCompany()
        this.companiesData = response
      } catch (error) {
        console.error(error)
      }
    },
    sortCompaniesData () {
      this.companiesData.sort((a, b) => {
        const valueA = a[this.sortField]
        const valueB = b[this.sortField]

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return this.sortOrder === 'asc' ? valueA - valueB : valueB - valueA
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return this.sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)
        }

        return 0
      })
    },
    formatToTwoDecimals
  }
}
</script>

<style lang="scss" src="./ComplianceManager.scss" scoped></style>
