import axios from '../plugins/axios'

export const getInvoiceCompanies = (companyId, skip, take) =>
  axios.get('/invoice/companies', {
    params: { companyId, skip, take }
  })
export const getInvoices = (number, date, skip, take) =>
  axios.get('/invoices', {
    params: { number, date, skip, take }
  })
export const getInvoiceCompany = (companyId, startDate, endDate) => axios.get(`/invoice/company/${companyId}`, {
  params: {
    ...(startDate && { startDate }),
    ...(endDate && { endDate })
  }
})
export const getInvoiceProcess = id => axios.get(`/invoice/${id}/process`)
export const createInvoice = data => axios.post('/invoice', data)
export const updateInvoice = (id, data) => axios.put(`/invoice/${id}`, data)
export const getInvoice = id => axios.get(`/invoice/${id}`)
export const removeInvoiceInProcesses = (invoiceId, processIds) => axios.post('/invoice/delete', { invoiceId, processIds })
export const deleteInvoice = id => axios.delete(`/invoice/${id}`)
