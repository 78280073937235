import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routers'
import Store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  Store.dispatch('fetchWhatsapp', to.meta.whatsapp ?? true)
  const user = localStorage.getItem('auth')

  if (to.path === '/login' && user) {
    next({ path: from.path })
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!user) {
      next({ path: '/login' })
    }

    next()
  } else {
    next()
  }
})

export default router
