import moment from 'moment-timezone'
import { processStatusVal, SERVICE_STATUS, servicesStatusVal } from './constants'
import { getRoleName } from '../services/roles'

moment.locale('es')
moment.tz.setDefault('America/Bogota')

const defaultFormat = 'dddd DD [de] MMMM hh:mm a, YYYY'

export function getTodayFormat () {
  return (format) => moment().format(format || defaultFormat)
};

export function formatDate () {
  return (date, format) => {
    return moment(date).format(format || defaultFormat)
  }
};

export function formatDateUTC () {
  return (date, format) => {
    return moment(date).utc().format(format || defaultFormat)
  }
};

export function getProcessStatus () {
  return (status) => {
    const find = processStatusVal.find(
      (processStatus) => processStatus.value === status
    )
    return !find ? status : find.text
  }
};

export function getServiceStatus () {
  return (status) => {
    const find = servicesStatusVal.find(
      (serviceStatus) => serviceStatus.value === status
    )
    return !find ? status : find.text
  }
};

export function getError () {
  return (status) => {
    switch (status) {
      case 400:
        return 'error'
      case 401:
        return 'warning'
      default:
        return 'info'
    }
  }
};

export function firstUpper () {
  return str => str.charAt(0).toUpperCase() + str.slice(1)
};

export function getStatusService () {
  return (service) => ({
    'green--text text--darken-4': service.status === SERVICE_STATUS.APPROVED,
    'red--text': service.status === SERVICE_STATUS.NOT_APPROVED,
    'gray--text':
      service.status === SERVICE_STATUS.PENDING ||
      service.status === SERVICE_STATUS.ASSIGNED,
    'text-decoration-line-through': service.status === SERVICE_STATUS.CANCELLED
  })
};

export function getRolName () {
  return (rol) => getRoleName(rol)
};

export function getStatusServiceChip () {
  return (status) => {
    switch (status) {
      case SERVICE_STATUS.APPROVED:
        return 'green'
      case SERVICE_STATUS.NOT_APPROVED:
        return 'red'
      case SERVICE_STATUS.CANCELLED:
        return 'grey'
      default:
        return 'info'
    }
  }
};

export const formatToTwoDecimals = (num) => {
  if (typeof num !== 'number' && isNaN(parseFloat(num))) {
    throw new Error('El valor proporcionado debe ser un número válido.')
  }

  if (Number.isInteger(num)) {
    return num
  }

  return parseFloat(num).toFixed(2)
}
