<template src="./Home.html"></template>

<script>
import Companies from '@/components/Forms/Companies/Companies'
import AnimatedCounter from '@/components/AnimatedCounter/AnimatedCounter'
import { getDashboardReport } from '../../services'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: { Companies, AnimatedCounter },
  data: () => ({
    ranges: [
      {
        id: 'ACTIVE_MONTH',
        name: 'Mes actual'
      },
      {
        id: 'LAST_MONTH',
        name: 'Mes anterior'
      },
      {
        id: 'LAST_3_MONTHS',
        name: 'Últimos 3 meses'
      }
    ],
    search: {
      companyId: null,
      range: 'ACTIVE_MONTH'
    },
    processDone: 0,
    ansServices: {
      totalANS: 0,
      totalServices: 0,
      averageAns: 0
    }
  }),
  created () {
    this.searchReport()
  },
  methods: {
    async searchReport () {
      try {
        const { processDone, ansServices } = await getDashboardReport(
          this.search.range,
          this.search.companyId
        )

        this.processDone = processDone
        this.ansServices = ansServices
      } catch (err) {
        console.log('search reports:', err)
      }
    },
    validUser () { // TEMPORAL
      return this.user?.email === 'garox55@gmail.com' ||
        this.user?.email === 'gerencia@xanter.co' ||
        this.user?.email === 'gerencia@riskshield.com.co' ||
        this.user?.email === 'andresfelipesantana@gmail.com'
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    })
  }
}
</script>
