<template src="./ListInvoices.html"></template>

<script>
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import { getInvoices, deleteInvoice, getInvoiceProcess } from '../../../services'
import { formatDateUTC } from '../../../utils/computeds'

export default {
  name: 'Invoice',
  data: () => ({
    menu: false,
    disabled: false,
    skip: 0,
    take: 50,
    search: {
      number: null,
      date: moment().format('YYYY-MM')
    },
    invoice: { number: null },
    invoices: [],
    processes: [],
    processesModal: false
  }),
  validations () {
    return {
      invoice: {
        date: { required },
        number: { required },
        total: { required }
      }
    }
  },
  created () {
    this.searchInvoices()
  },
  methods: {
    async searchInvoices (add) {
      try {
        const invoices = await getInvoices(this.search.number, this.search.date, this.skip, this.take)

        if (!add) this.invoices = invoices
        else this.invoices.push(...invoices)

        if (this.skip + this.take > this.invoices.length) this.disabled = true
        else this.disabled = false
      } catch (error) {
        console.log('Error on get invoices =>', error)
      }
    },
    async more () {
      this.skip += this.take
      await this.searchInvoices(true)
    },
    async listProcesses (invoice) {
      this.invoice = invoice
      this.processes = await getInvoiceProcess(invoice.id)
      this.processesModal = true
    },
    ifDeleteInvoice (invoiceId) {
      this.$swal({
        title: '¿Está seguro de eliminar la factura?',
        icon: 'warning',
        buttons: true,
        dangerMode: true
      }).then((value) => {
        if (value) {
          this.removeInvoice(invoiceId)
        }
      })
    },
    async removeInvoice (invoiceId) {
      try {
        await deleteInvoice(invoiceId)
        this.$swal({
          text: 'Se elimino la factura.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.searchInvoices()
      } catch (error) {
        console.log('Error on delete invoice =>', error)
        this.$swal({
          text: error.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    }
  },
  computed: {
    formatDateUTC
  }
}
</script>
