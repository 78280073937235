<template src="./AnimatedCounter.html"></template>
<script>
export default {
  props: {
    count: { type: Number, required: true }
  },
  data () {
    return {
      displayCount: 0
    }
  },
  watch: {
    count (newVal) {
      this.animateCount(newVal)
    }
  },
  methods: {
    animateCount (target) {
      const start = this.displayCount
      const duration = 1000
      let startTime = null

      const step = (timestamp) => {
        if (!startTime) startTime = timestamp
        const progress = (timestamp - startTime) / duration
        if (progress < 1) {
          this.displayCount = Math.floor(start + (target - start) * progress)
          requestAnimationFrame(step)
        } else {
          this.displayCount = target
        }
      }

      requestAnimationFrame(step)
    }
  }
}
</script>

<style scoped lang="scss">
.animated-counter {
  .count-text {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1976d2;
  }
}
</style>
