<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import { login, loginGoogle, forget, getUser } from '../../services'
import { TASK_STATUS } from '../../utils/constants'

export default {
  name: 'Login',
  data () {
    return {
      user: {},
      forget: false
    }
  },
  validations () {
    const user = {
      email: { email, required },
      password: {}
    }
    if (!this.forget) user.password = { required, minLength: minLength(6) }

    return { user }
  },
  methods: {
    async signIn () {
      try {
        if (this.$v.$invalid) {
          return false
        } else {
          const user = await login(this.user.email, this.user.password)
          await this.pushUser(user.user)
        }
      } catch (error) {
        this.errorsFirebase(error)
      }
    },
    async signInGoogle () {
      try {
        const google = await loginGoogle()
        await this.pushUser(google.user)
      } catch (error) {
        this.errorsFirebase(error)
      }
    },
    async forgetPass () {
      try {
        await forget(this.user.email)
        this.$swal({
          text: 'Correo enviado.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.forget = false
      } catch (error) {
        this.errorsFirebase(error)
      }
    },
    errorsFirebase (error) {
      switch (error.code) {
        case 'auth/wrong-password':
          this.user.password = ''
          this.$swal({
            text: 'Contraseña incorrecta',
            buttons: false,
            icon: 'error',
            timer: 2000
          })
          break
        case 'auth/user-not-found':
          this.$swal({
            text: `El usuario ${this.email} no existe.`,
            buttons: false,
            icon: 'error',
            timer: 2000
          })
          break
        case 'auth/user-disabled':
          this.$swal({
            text: 'La cuenta de usuario se encuentra inactiva.',
            buttons: false,
            icon: 'error',
            timer: 2000
          })
          break
        case 'auth/invalid-email':
          this.$swal({
            text: `El correo ${this.email} no está registrado.`,
            buttons: false,
            icon: 'error',
            timer: 2000
          })
          break
        default:
          console.log(
            `Ocurrió un error con codigo: ${error.code} - ${error.message}`
          )
          this.$swal({
            text: 'Ocurrió un error con codigo',
            buttons: false,
            icon: 'error',
            timer: 2000
          })
      }
    },
    async pushUser (user) {
      const userActive = await getUser(user.uid)

      switch (userActive.rol) {
        case 'COMPANY_COMPLIANCE':
          this.$router.replace({ path: '/cumplimiento-usuario', query: { status: TASK_STATUS.EXECUTED } })
          break
        default:
          this.$router.replace({ name: 'Home' })
      }
    }
  }
}
</script>
<style src="./Login.scss" lang="scss" scoped></style>
<template src="./Login.html"></template>
